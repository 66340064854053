@import "colors";
@import "typography";

$body-bg: var(--grey05);
$body-color: var(--husk);

// Add additional spacing utilities
$spacer: 1rem;

$spacers: (
  6: (
    $spacer * 5,
  ),
  7: (
    $spacer * 10,
  ),
);
