// dont display the 'x's on legacy edge
input::-ms-clear {
  display: none;
}

a {
  color: var(--sea);
  font-weight: 600;
}

a:hover {
  color: var(--darkSea);
  text-decoration: none;
}

h3 {
  font-weight: 700;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal-backdrop {
  background: #000008;
}

ul,
ol {
  &.no-bullet {
    list-style: none;
    padding-left: 0;
  }
}

.modal-custom-title {
  color: var(--husk);
  font-family: ArcoPerpetuoPro;
  font-size: var(--textXL);
  font-weight: 600;
  text-align: left;
}

.modal-field-label {
  color: var(--rock);
  font-size: var(--textXS);
  text-transform: uppercase;
  font-weight: 600;
}

.modal-footer {
  border-top: none;
  padding-bottom: 0px;
  padding-top: 60px;
  padding-right: 0px;
}

.modal-content {
  padding: 30px;
  width: 100%;
  border-radius: 0.66rem;
}

.modal-body {
  padding: 0;
}

.modal-dialog {
  max-width: 570px;
}

.close {
  border: 2px solid var(--grey03) !important;
  border-radius: 100% !important;
  height: 48px;
  width: 48px;
  vertical-align: middle;
  font-size: 1.4rem;
}

.modal-body h3 {
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-size: var(--textXL);
}

// Override ridiculously high z-index set by intercom so it does not conflict with modal / fixed elements
.intercom-launcher-frame,
.intercom-frame,
.intercom-lightweight-app {
  z-index: 2 !important;
}
