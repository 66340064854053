@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-Black.ttf");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-ExtraBold.ttf");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-Light.ttf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: CoconutRegular;
  src: url("/assets/fonts/ArcoPerpetuoPro-ExtraLight.ttf");
  font-weight: 100;
  font-style: normal;
}

$font-family-sans-serif: "CoconutRegular", Arial, sans-serif !default;
$font-family-base: $font-family-sans-serif !default;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --textXXL: 32px;
  --textXL: 24px;
  --textL: 20px;
  --textM: 16px;
  --textS: 14px;
  --textXS: 12px;
}
